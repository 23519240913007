<template>
  <div>
    <div class="box">
      <div class="title_box flex">
        <div class="flex" style="flex-wrap: wrap;">
          <h3 v-show="Title.id == 0 || Title.id == 1" :class="Title.id == 0 ? 'TitleHover' : 'h3'"
              @click="TitleShow('提现记录列表', 0)">
            提现记录列表
          </h3>
          <h3 v-show="Title.id == 0 || Title.id == 1" :class="Title.id == 1 ? 'TitleHover' : 'h3'"
              @click="TitleShow('支付宝设置', 1)">
            支付宝设置
          </h3>
          <h3 v-show="Title.id == 2" :class="Title.id == 2 ? 'TitleHover' : 'h3'">
            申请提现
          </h3>
          <p style="width: 100%;margin-top: 10px;color: #ff0000; font-size: 16px;">
            提现10-15个工作日内处理完毕,周末节假日除外,最迟不超过15天</p>
        </div>
        <div class="flex">
          <button @click="TitleShow('申请提现', 2)" class="add_but" v-show="Title.id == 0">
            申请提现
          </button>
          <!-- <button class="add_but2" v-show="Title == '支付宝设置'">取消</button> -->
          <button class="add_but3" v-show="Title.id == 1" @click="alipaySet()">保存</button>
          <button class="add_but3" v-show="Title.id == 2" @click="TitleShow('提现记录列表', 0)">返回</button>
          <button class="add_but3" v-show="Title.id == 2" @click="Tocash()">提交</button>
        </div>
      </div>
      <div class="ct_box" v-show="Title.id == 0">
        <div class="search_status_box flex">
          <div class="search_box flex">
            <order_time @get_time="get_time"/>
          </div>
          <orderStatus width="250" :status_list="status_list" @status_tab="status_tab" :order_status="status"
                       :category_id="1"/>
        </div>
        <!-- <table class="table">
          <thead>
            <tr>
              <td>申请时间</td>
              <td>提现金额</td>
              <td>提现方式</td>
              <td>户名</td>
              <td>姓名</td>
              <td>受理时间</td>
              <td>处理状态</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in tableData" :key="index">
              <td>{{ item.ctime }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ typeList[item.type] }}</td>
              <td>{{ item.account }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.utime }}</td>
              <td
                :style="{ color: item.status == 0 ? '#333' : item.status == 1 ? '#00B052' : '#FF0000' }">
                {{
            statusList[item.status] }} <span v-if="item.status == 2">: {{ item.reason }}</span>
              </td>
            </tr>
          </tbody>
        </table> -->
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="ctime" label="申请时间" width="200">
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="amount" label="提现金额" width="200">
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="提现方式" width="200">
            <template slot-scope="scope">
              {{ scope.row.type === 1 ? '支付宝' : '' }}
            </template>
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           align="center" header-align="center" prop="account" label="户名" width="250">
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="name" label="姓名" width="250">
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" prop="utime" label="受理时间" width="250">
          </el-table-column>
          <el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
                           header-align="center" align="center" label="处理状态" width="200">
            <template slot-scope="scope">
              <div
                  :style="{ color: scope.row.status == 0 ? '#333' : scope.row.status == 1 ? '#00B052' : '#FF0000' }">
                {{ statusList[scope.row.status] }}
                <span v-if="scope.row.status == 2">: {{ scope.row.reason }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>
      </div>
      <div class="ct_box" v-show="Title.id == 1">
        <div class="flex" style="align-items: center; margin-bottom: 15px">
          <p class="text_title">支付宝账号:</p>
          <div class="text_input">
            <el-input v-model="AlipayId" placeholder="输入支付宝账号"></el-input>
          </div>
        </div>
        <div class="flex" style="align-items: center">
          <p class="text_title">姓名:</p>
          <div class="text_input">
            <el-input v-model="name" placeholder="输入姓名"></el-input>
          </div>
        </div>
      </div>
      <div class="ct_box" v-show="Title.id == 2">
        <el-radio-group v-model="form.type" style="margin-bottom: 30px;">
          <el-radio-button :label='1'>充值金额</el-radio-button>
          <el-radio-button :label='2'>收益金额</el-radio-button>
        </el-radio-group>
        <div>
          <div class="flex">
            <p class="text_remainingSum">{{ form.type == 1 ? '充值金额:' : '收益余额:' }}</p>
            <span class="money">￥{{ form.type == 1 ? user_info.money : user_info.wallet }}</span>
          </div>
          <div class="flex" style="align-items: center; margin-bottom: 15px;">
            <p class="text_title">提现金额:</p>
            <div class="text_input">
              <el-input @input="changeDot" v-model="form.amount" placeholder="输入金额"></el-input>
            </div>
            元
          </div>
        </div>
        <!-- <div v-show="form.type == 2">
          <div class="flex">
            <p class="text_remainingSum">收益余额:</p>
            <span class="money">￥</span>
          </div>
          <div class="flex" style="align-items: center; margin-bottom: 15px;">
            <p class="text_title">提现金额:</p>
            <div class="text_input">
              <el-input @input="changeDot" v-model="form.amount" placeholder="输入金额"></el-input>
            </div>
            元
          </div>
        </div> -->
        <p class="hitm" v-show="form.type == 1">提示：{{
            `提现充值金额，需扣除10%手续费,实际到账：${formatNumber ? formatNumber : '0.00'}`
          }}。</p>
        <div class="flex" style="align-items: center">
          <p class="text_title">提现方式:</p>
          <div style="margin-left: 15px">
            <el-radio v-model="form.radioType" :label='1'>支付宝</el-radio>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
import order_time from '@/components/order_time'
import orderStatus from '@/components/orderStatus'

export default {
  components: {
    Title,
    order_time,
    orderStatus
  },
  mounted() {
    this.get_list();
    this.user_info = JSON.parse(localStorage.getItem('user_info'))
  },
  computed: {
    formatNumber: function () {
      // 匹配整数
      let formatNum = this.form.amount
      let transactionFee = 0
      let resultAfterFee = 0
      const integerPattern = /^\d+$/;
      // 匹配带一位小数的数字
      const oneDecimalPattern = /^\d+\.\d$/;
      if (this.form.type == 1) {
        transactionFee = formatNum * 9 / 10
      }
      if (integerPattern.test(transactionFee)) {
        // 如果是整数，补.00
        return transactionFee + '.00'
      } else if (oneDecimalPattern.test(transactionFee)) {
        // 如果是带一位小数的数字，补0.10
        return transactionFee + '0';
      } else {
        return transactionFee.toFixed(2)
      }
    }
  },
  data() {
    return {
      resource: '',
      orderId: '',
      status_list: [{id: '', title: '全部',}, {id: '0', title: '待审核',}, {id: '1', title: '通过',}, {
        id: '2',
        title: '拒绝',
      },],
      Title: {
        id: 0,
        name: '提现记录列表'
      },
      status: '',
      AlipayId: '', //支付宝账号
      name: '', //名称
      check: false, //提现方式
      form: {
        type: 1,
        amount: 0,
        radioType: 0,
      },
      typeList: {1: '支付宝'},
      statusList: {0: '待处理', 1: '通过', 2: '拒绝'},
      tableData: [],
      page: 1,
      total_page: 0,
      count: 0, //总条数
      time: [],
      user_info: {},
    }
  },
  watch: {
    'form.type': {
      handler(newValue, oldVale) {
        if (newValue != oldVale) {
          this.form.amount = ''
          this.form.radioType = 0
        }
      },
      deep: true,
    },
  },
  methods: {
    get_time(time) {
      this.time = time || []
      this.pageChange(1)
    },
    changeDot(value) {
      console.log(value);
      const regex = /^\d+(\.\d{1,2})?$/;
      if (!regex.test(value)) {

      }
    },
    status_tab(id) {
      this.status = id
      this.get_list()
    },
    TitleShow(item, type) {
      this.Title = {
        id: type,
        name: item
      }
      if (type == 1) {
        this.curlGet("/api/users/alipay/get").then((res) => {
          if (res.data.code) {
            (this.AlipayId = res.data.data.account),
                (this.name = res.data.data.name)
          }
        })
      }
    },
    pageChange(pageVal) {
      this.page = pageVal
      this.get_list()
    },
    get_list() {
      let where = {}
      where.page = this.page
      where.limit = 20
      if (this.status) {
        where.status = this.status
      }
      if (this.time.length) {
        where.start_time = this.time[0] / 1000;
        where.end_time = this.time[1] / 1000;
      }
      this.curlGet("/api/users/tocash", where).then((res) => {
        if (res.data.code) {
          this.tableData = res.data.data.list
          // console.log(111, this.tableData)
          this.total_page = res.data.data.total_page
          this.page = this.page
          this.count = res.data.data.count
        }
      })
    },
    // 筛选
    searchBut() {
      this.get_list();
    },
    // 支付宝设置
    alipaySet() {
      if (this.AlipayId == '') {
        this.$message({
          type: 'error',
          message: '支付宝账号不能为空'
        });
        return false;
      }
      if (this.name == '') {
        this.$message({
          type: 'error',
          message: '姓名不能为空'
        });
        return false;
      }
      this.curlPost("/api/users/alipay/set", {
        'account': this.AlipayId,
        'name': this.name,
      }).then((res) => {
        if (res.data.code) {
          this.$message({
            type: 'success',
            message: '设置成功'
          });
        }
      })
    },
    // 提现
    Tocash() {
      // if (this.amount < 100) {
      // 	this.$message({ type: 'error', message: '提现最低金额100' });
      // 	return false;
      // }
      if (this.form.radioType <= 0) {
        return this.$message({type: 'error', message: '选择提现方式'})
      }
      console.log(typeof (parseInt(this.form.amount)));

      if (this.form.type == 1 && parseInt(this.form.amount) > this.user_info.money) {
        return this.$message({type: 'error', message: '当前余额金额不足，请确认提现金额'});

      }
      if (this.form.type == 2 && parseInt(this.form.amount) > this.user_info.wallet) {
        return this.$message({type: 'error', message: '当前收益金额不足，请确认提现金额'});

      }
      let data = {}
      data.amount = this.form.amount
      data.type = this.form.type//支付类型，1是充值提现，2是收益提现
      this.curlPost("/api/users/tocash/save", data).then((res) => {
        if (res.data.code) {
          this.$message({
            type: 'success',
            message: '提现申请提交成功，请耐心等待!'
          })

          // 获取用户信息存入 local storage 更新用户钱包余额
          this.getUserInfo()
          this.form.amount = 0
          this.form.radioType = 0
          setTimeout(() => {
            this.$router.go(0)
          }, 1500)
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });
        }
      })
    },
    // 获取用户信息
    getUserInfo() {
      this.curlGet('/api/user/info').then((res) => {
        if (res.data.code) {
          this.$nextTick(() => {
            this.$util.setLocalStorage('user_info', res.data.data, 200)
            this.user_info = res.data.data
            // console.log(this.user_info);
          })

        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/Withdraw';
@import '@/scss/placard_media';
</style>